@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,400&display=swap');

* {
  -webkit-touch-callout:none;                 /* prevent callout to copy image, etc when tap to hold */
  -webkit-tap-highlight-color:transparent;    /* prevent tap highlight color / shadow */
  user-select:none;                           /* prevent copy paste, to allow, change 'none' to 'text' */
  /* -webkit-text-size-adjust:none;             prevent webkit from resizing text to fit */
  /* outline: 1px dashed red; */
}

/* Hide scrollbar */
/* =========================================== */
/* 
::-webkit-scrollbar { Crome, Safari, Opera
  width: 0px;
  background: transparent;
}
*{
  -ms-overflow-style: none;  IE, Edge
  scrollbar-width: none;  Firefox
} 
*/
/* =========================================== */

/* Hide scrollbar apenas das modais */
div::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
/* Hide scrollbar apenas das modais */
div{
  /* IE and Edge */
  -ms-overflow-style: none;
  /* firefox */
  scrollbar-width: none;  
}

/* Change geral autofill color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  box-shadow: 0 0 0 30px rgba(var(--color03), 0.2) inset;
}

/* MAIN COLORS  */
:root {
  /* TEMA VERDE */
  --color00: 9, 134, 91;
  --color01: 49,134,91;
  --color02: 69,154,91;
  --color03: 89,154,91;

  --colorRed: 199, 30, 20;
  --colorGreen: 10, 118, 100;
  --colorYellow: 169, 100, 20;
}

body{
  font-family: "Nunito", "Roboto", "Helvetica", "Arial", sans-serif!important;
  background: rgb(244, 244, 244);

  /* para o texto não ultrapassar a largura da tela */
  overflow-wrap: break-word; 
  word-wrap: break-word;
  word-break: break-word;
}

p, a{
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
}

a {
  color: rgb(var(--color01))!important;
  text-decoration: none!important;
}

a:hover {
  color: rgb(var(--color03))!important;
}

/* AUTOFILL ------------------------------------------------------- */
.MuiOutlinedInput-input:-webkit-autofill {
  box-shadow: 0 0 0 30px rgba(var(--color03), 0.2) inset;
}
/* #email:-webkit-autofill,
#password:-webkit-autofill {
  box-shadow: 0 0 0 30px rgba(var(--color03), 0.2) inset !important;
} */

/* BUTTONS ------------------------------------------------------- */
/* .MuiButton-root {
  text-transform: none!important; 
}  */
.MuiButton-containedPrimary{
  color: #fff;
  background-color: rgb(var(--color01));
}
.MuiButton-containedPrimary:hover{
  background-color: rgb(var(--color02));
}
.MuiButton-contained.Mui-disabled{
  background-color: rgba(0, 0, 0, 0.12)!important;
}
.MuiButton-startIcon{
  pointer-events: none!important; /* pointerEvents none necessário para que o clique no button chame o handleAlternativeClick corretamente */
}

/* CHECKBOX ------------------------------------------------------- */
.MuiCheckbox-root.Mui-checked{
  color: rgba(var(--color01))!important;
}

/* INPUTS ------------------------------------------------------- */
label.MuiFormLabel-root.Mui-focused {
  color: rgb(var(--color01))!important;
}
.Mui-focused fieldset{
  border-color: rgb(var(--color01))!important;
}
.MuiOutlinedInput-root:hover fieldset{
  border-color: rgb(var(--color01))!important;
}

/* MuiOutlinedInput-root */

/* AUTOCOMPLETE INPUT */
.MuiAutocomplete-inputRoot{
  background-color: rgba(245,245,245,0.76);
} 
#driven-study-configs .MuiAutocomplete-inputRoot{
  background-color: transparent;
} 
/* AUTOCOMPLETE POPPER */
.MuiAutocomplete-listbox{
  background-color: rgba(245,245,245,0.76);
}
.MuiAutocomplete-popper{
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
  background-color: rgba(250,250,250,1);
  border-radius: 4px;
}
.MuiAutocomplete-popper li div{ 
  background-color: rgba(245,245,245,0.76);
  font-weight: bold;
  color: rgba(var(--color01),1);
  /* color: rgb(var(--colorGreen)); */
}
/* .MuiAutocomplete-popper li > div:hover{
  color: red;
}  */

/* SELECT INPUT */
/* MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input */
.MuiSelect-outlined{
  background-color: rgba(245,245,245,0.76);
  /* color: rgba(0,0,0,.6); */
}
#driven-study-configs .MuiSelect-outlined{
  background-color: transparent;
}
/* SELECT POPPER */
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper{
  /* color: red; */
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
  background-color: rgba(250,250,250,1);
}

/* LISTS ------------------------------------------------------- */
.MuiList-padding {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

/* LINEAR PROGRESS --------------------------------------------- */
.MuiLinearProgress-root {
  background-color: rgba(var(--color01),0.38);
}
.MuiLinearProgress-bar{
  background-color: rgba(var(--color01),0.67);
} 

.progressBar {
  width: 100%;
  height: 10px;
}

/* SNACKBAR ERROR ------------------------------------------------------- */
/* .MuiAlert-root.MuiAlert-filledError.MuiAlert-filled{
  background-color: rgba(var(--color01),1);
} */


/* SIDE MENU  ------------------------------------------------------- */
#side-menu .MuiListSubheader-root{
  background-color: rgb(12,66,45);
  /* background-color: rgb(20, 136, 90); */
  /* background-color: rgb(20, 148, 90); */
  box-shadow: 0 1px 10px rgba(0,0,0,.3); 
  color: white;
}
/* .MuiPaper-root,  */
#side-menu .MuiDrawer-paper{
  /* background-color: rgb(111,118,113); */
  /* background-color: rgb(20, 136, 90); */
  /* background-color: rgb(20, 148, 90); */
  background-color: rgb(45,79,62);
  color: white;
}
#side-menu .MuiListItemIcon-root{
  color: white;
}
/* #side-menu .MuiDivider-root{ */
  /* border-width: 0; */
  /* border-style: solid; */
  /* border-color: rgba(0, 0, 0, 0.12); */
  /* border-bottom-width: thin; */
/* } */


/* AVATAR ------------------------------------------------------- */
.MuiAvatar-root{
  background-color: rgb(var(--color01));
}

/* ANIMATED DOTS ... */
.animated-dots-after:after {
  content: '.';
  animation: black-dots 0.8s steps(5, end) infinite;
}
@keyframes black-dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: black;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    color: black;
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    color: black;
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;}
}


/* SWIPER (CAROUSEL) */
swiper-container {
  width: 100%;
  height: 100%;
}
swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}